<script>
  /* eslint-disable*/
  import has from 'lodash/has'
  import { Pie, mixins } from 'vue-chartjs'
  const { reactiveProp } = mixins

  export default {
    extends: Pie,
    mixins: [reactiveProp],
    props: {
      chartData: Object,
      options: Object,
    },
    watch: {},
    mounted() {
      let linearStroke = [0, 0, 0, 450]

      const backgroundColor = []
      this.chartData.datasets.map((obj) => {
        if (has(obj, 'gradientFill') && obj.gradientFill.length) {
          linearStroke = has(obj, 'linearStroke') ? obj.linearStroke : linearStroke
          const backgroundColor = []
          obj.gradientFill.map((gradientSet) => {
            const gradientFill = this.$refs.canvas
              .getContext('2d')
              .createLinearGradient(...linearStroke)
            gradientSet.forEach((hex, index) => {
              if (typeof hex === 'object' && has(hex, 'stop') && has(hex, 'color')) {
                const { stop, color } = hex
                gradientFill.addColorStop(stop, color)
              } else {
                let stop = index > 0 ? 1 / index : 0
                stop = Number(stop.toFixed(1))
                gradientFill.addColorStop(stop, hex)
              }
            })
            backgroundColor.push(gradientFill)
          })

          if (has(obj, 'backgroundColor') === false) {
            obj.backgroundColor = backgroundColor
          }
        }
      })
      if (has(this.chartData.datasets, 'backgroundColor') === false) {
        this.chartData.datasets.backgroundColor = backgroundColor
      }

      this.renderChart(this.chartData, this.options)
    },
    beforeDestroy() {
      this.$data._chart.destroy()
    },
  }
</script>
