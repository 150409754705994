<template>
  <v-row class="mx-0 basic-stats">
    <!-- <v-col cols="12" sm="6" lg="3" md="4" class="px-2 pt-0"
      ><pie-chart
        :title="'Empresas por Nivel'"
        :chartData="dataCompaniesByLevel"
        :chartOptions="options"
        :leyend="levelsLeyend"
        :loading="loading"
        :key="index"
    /></v-col> -->
    <v-col cols="12" sm="6" lg="3" md="4" class="px-2 pt-0"
      ><pie-chart
        :title="'Empresas por Tipo'"
        :chartData="dataCompaniesByType"
        :chartOptions="options"
        :leyend="typesLeyend"
        :loading="loading"
        :key="index"
        :helpId="4"
    /></v-col>
    <v-col cols="12" sm="6" lg="3" md="4" class="px-2 pt-0"
      ><pie-chart
        :title="'Licencias Disponibles'"
        :chartData="dataCompaniesBalance"
        :chartOptions="options"
        :leyend="balanceLeyend"
        :loading="loading"
        :key="index"
        :helpId="5"
    /></v-col>
    <v-col cols="12" sm="6" lg="3" md="4" class="px-2 pt-0">
      <v-card class="text-center" style="background-color: #f5f5f5" :loading="loading">
        <v-card-title style="height: 35px; background-color: #e0e0e0">
          <h5>Resumen de Licencia</h5>
          <v-spacer />
          <help :helpId="6" />
        </v-card-title>

        <v-divider />
        <v-card-title
          class="justify-center text-xl-body-1 text-lg-body-2 text-md-body-2 text-sm-body-2 text-xs-body-2"
        >
          Usuarios Registrados
        </v-card-title>
        <v-col cols="12">
          <v-progress-linear
            v-model="dataUsersBalance.datasets[0].data[0]"
            color="blue-grey"
            height="18"
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }} / 200</strong>
            </template>
          </v-progress-linear>
        </v-col>
        <v-divider />
        <v-card-title
          class="justify-center text-xl-body-1 text-lg-body-2 text-md-body-2 text-sm-body-2 text-xs-body-2"
        >
          Empresas Registradas
        </v-card-title>
        <v-col cols="12">
          <v-progress-linear
            v-model="dataCompaniesBalance.datasets[0].data[0]"
            color="blue-grey"
            height="18"
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }} / 50</strong>
            </template>
          </v-progress-linear>
        </v-col>
        <v-divider style="margin-bottom: 18px" />
        <v-col cols="12" style="margin-bottom: 0px">
          <v-btn elevation="2">Actualizar plan</v-btn>
        </v-col>
        <!-- <v-divider />
        <v-footer padless style="min-height: 155px">
          <v-col class="text-h5" cols="12">
            120,00 $ <br />
            <small>Mensual</small>
          </v-col>
        </v-footer> -->
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  import PieChart from '@/components/Stats/SinglePieChart'
  import Help from '@/components/App/Help'
  export default {
    data: () => {
      return {
        loading: true,
        companiesData: [],
        index: 1,
        dataCompaniesByLevel: {
          labels: ['Nivel 1', 'Nivel 2', 'Nivel 3'],
          datasets: [
            {
              gradientFill: [['#2F5597'], ['#00B0F0'], ['#008EB9'], ['#26c6da']],
              data: [40, 40, 15, 10],
            },
          ],
        },
        dataCompaniesByType: {
          labels: ['Nivel 1', 'Nivel 2', 'Nivel 3', 'Nivel 4'],
          datasets: [
            {
              gradientFill: [
                ['#2F5597'],
                ['#00B0F0'],
                ['#008EB9'],
                ['#5b5b5e'],
                ['#c3c5ca'],
              ],
              data: [40, 40, 15, 10],
            },
          ],
        },
        dataCompaniesBalance: {
          labels: ['Creadas', 'Por Crear'],
          datasets: [
            {
              gradientFill: [
                ['#003a56'],
                ['#c3c5ca'],
                ['#72c6ee'],
                ['#5b5b5e'],
                ['#009ada'],
              ],
              data: [7, 43],
            },
          ],
        },
        dataUsersBalance: {
          labels: ['Creados', 'Por Crear'],
          datasets: [
            {
              gradientFill: [
                ['#003a56'],
                ['#c3c5ca'],
                ['#72c6ee'],
                ['#5b5b5e'],
                ['#009ada'],
              ],
              data: [4, 100],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            position: 'bottom',
            width: '70%',
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          plugins: {
            datalabels: {
              color: 'white',
              textAlign: 'center',
              font: {
                weight: 'bold',
                size: 16,
              },
            },
          },
        },
        levelsLeyend: {
          height: '160px',
          items: [
            { color: '#2F5597', text: 'Nivel 1' },
            { color: '#00B0F0', text: 'Nivel 2' },
            { color: '#008EB9', text: 'Nivel 3' },
          ],
        },
        typesLeyend: {
          height: '160px',
          items: [
            { color: '#2F5597', text: 'Empresa Principal' },
            { color: '#00B0F0', text: 'Empresa Master' },
            { color: '#008EB9', text: 'Franquiciado' },
            { color: '#003a56', text: 'Consultores' },
            { color: '#c3c5ca', text: 'Empresas de servicios' },
          ],
        },
        balanceLeyend: {
          height: '160px',
          items: [
            { color: '#003a56', text: 'Creadas' },
            { color: '#c3c5ca', text: 'Por Crear' },
          ],
        },
      }
    },
    components: { PieChart, Help },
    watch: {
      dataCompaniesByLevel() {
        this.index++
      },
    },
    created() {
      this.getStats()
    },
    methods: {
      getStats() {
        this.loading = true
        this.$axios.get('company-management/get-statistics').then((response) => {
          this.companiesData = Object.entries(response.data.levels)
          this.usersStats = response.data.users
          const { labels: levelLabels, values: levelValues, total } = this.getData(
            response.data.levels
          )
          this.dataCompaniesByLevel.labels = levelLabels
          let levelIndex = 0
          levelLabels.forEach((label) => {
            this.levelsLeyend.items[levelIndex].text = label
            levelIndex++
          })
          this.dataCompaniesByLevel.datasets[0].data = levelValues
          const { labels: typeLabels, values: typeValues } = this.getData(
            response.data.types
          )
          this.dataCompaniesByType.labels = typeLabels
          let typeIndex = 0
          typeLabels.forEach((label) => {
            this.typesLeyend.items[typeIndex].text = label
            typeIndex++
          })
          this.dataCompaniesByType.datasets[0].data = typeValues
          this.dataCompaniesBalance.datasets[0].data = [total, 30 - total]
          this.dataUsersBalance.datasets[0].data = [
            response.data.users.total,
            200 - parseInt(response.data.users.total),
          ]
          this.loading = false
          this.index++
        })
      },
      getData(data) {
        const labels = []
        const values = []
        let total = 0
        const arrayData = Object.entries(data)
        arrayData.forEach((item) => {
          if (item[1].total > 0) {
            labels.push(item[1].name)
            values.push(item[1].total)
            total = total + parseInt(item[1].total)
          }
        })
        return { labels, values, total }
      },
    },

    beforeDestroy() {},
  }
</script>
<style lang="scss">
  .basic-stats {
    .v-card__subtitle,
    .v-card__text,
    .v-card__title {
      padding: 5px;
    }
    .v-card {
      height: 100%;
    }
    .v-toolbar__content .v-btn.v-btn--icon.v-size--default,
    .v-toolbar__extension .v-btn.v-btn--icon.v-size--default {
      height: 20px;
      width: 20px;
    }
    .v-toolbar__content {
      padding: 5px 10px;
      height: 100% !important;
    }
  }
</style>
