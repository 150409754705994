<template>
  <div class="vuse-content-wrapper">
    <v-container fluid>
      <!--<smart-breadcrumbs :items="navigationElements" />-->
      <horizontal-menu :menuItems="availableMenuItems" />

      <basic-stats class="mb-4" />

      <v-sheet class="transparent">
        <help class="help--org-chart" :helpId="2" />
        <organization-chart
          class="organization-chart"
          :pan="true"
          :zoom="false"
          :datasource="companiesData"
        >
          <template v-slot="{ nodeData }">
            <company-card-1
              @selectedCompany="handleSelectedCompany($event)"
              :company="nodeData"
              :currentCompany="company"
            />
          </template>
        </organization-chart>
        <v-btn
          absolute
          bottom
          right
          small
          icon
          class="ma-5"
          @click="fullScreenDialog = true"
          ><v-icon>mdi-fullscreen</v-icon></v-btn
        >
      </v-sheet>

      <v-dialog v-model="fullScreenDialog" height="80%" :width="dialogWidth">
        <v-card class="lighten-3">
          <organization-chart
            class="dialog organization-chart"
            :pan="true"
            :zoom="true"
            :zoomin-limit="3"
            :zoomout-limit="0.5"
            :datasource="companiesData"
            @node-click="
              ''

            "
          >
            <template v-slot="{ nodeData }">
              <company-card-1
                @selectedCompany="handleSelectedCompany($event)"
                :company="nodeData"
                :currentCompany="company"
              />
            </template>
          </organization-chart>
          <v-btn icon absolute top right @click="fullScreenDialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="50%">
        <map-card :item.sync="selectedCompany" :loading="loadingCardCompany" />
      </v-dialog>
      <!-- <v-row class="mx-0">
      </v-row> -->
    </v-container>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import BasicStats from '@/components/CorporateStructure/BasicStats'
  import HorizontalMenu from '@/components/App/HorizontalMenu'
  import Help from '@/components/App/Help'
  import OrganizationChart from 'vue-organization-chart'
  import 'vue-organization-chart/dist/orgchart.css'
  import MapCard from '@/components/Cards/MapCard'
  import CompanyCard1 from '@/components/CorporateStructure/CompanyCard1'
  import { decryptData } from '@/utils/encryption'

  export default {
    name: 'CorporateStructureAdmin',
    components: {
      BasicStats,
      OrganizationChart,
      HorizontalMenu,
      MapCard,
      CompanyCard1,
      Help,
    },
    data() {
      return {
        fullScreenDialog: false,
        dialog: false,
        loading: false,
        loadingCardCompany: false,
        companiesData: {},
        selectedCompany: {},
        roles: decryptData(this.$session.get('roles')),
        menuItems: [
          {
            id: 1,
            name: 'Identidad Corporativa',
            icon: 'mdi-home',
            link: '/business/corporate-structure/identity',
            lang: 'es',
            role: 'corporate_identity',
            requiredRoles: ['super_admin', 'admin', 'admin_identity'],
          },
          {
            id: 2,
            name: 'Empresas',
            icon: 'mdi-home-assistant',
            link: '/business/corporate-structure/companies',
            lang: 'es',
            role: 'admin_companies',
            requiredRoles: ['super_admin', 'admin', 'admin_companies'],
          },
          {
            id: 3,
            name: 'Geolocalización de Empresas',
            icon: 'mdi-map',
            link: '/business/corporate-structure/map',
            lang: 'es',
            role: 'admin_companies',
            requiredRoles: ['super_admin', 'admin', 'admin_companies'],
          },
          {
            id: 4,
            name: 'Corporate identity',
            icon: 'mdi-cursor-default-click',
            link: '/business/corporate-structure/identity',
            lang: 'en',
            role: 'corporate_identity',
            requiredRoles: ['super_admin', 'admin', 'admin_identity'],
          },
          {
            id: 5,
            name: 'Companies',
            icon: 'mdi-home-assistant',
            link: '/business/corporate-structure/companies',
            lang: 'en',
            role: 'admin_companies',
            requiredRoles: ['super_admin', 'admin', 'admin_companies'],
          },
          {
            id: 6,
            name: 'Geolocation of Companies',
            icon: 'mdi-map',
            link: '/business/corporate-structure/map',
            lang: 'en',
            role: 'admin_companies',
            requiredRoles: ['super_admin', 'admin', 'admin_companies'],
          },
        ],
        navigationElements: [
          {
            text: 'Estructura Corporativa',
            disabled: true,
            href: '/business/corporate-structure/admin-panel',
          },
        ],
      }
    },
    created() {
      this.getCompanies()
    },
    watch: {
      company() {},
    },
    computed: {
      ...mapGetters(['locale', 'userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      handleMenuLang() {
        return this.menuItems.filter((item) => item.lang === this.locale)
      },
      assignedRoles() {
        if (this.roles && this.roles.length > 0) {
          const roles = []
          this.roles.forEach((role) => {
            roles.push(role.name)
          })
          return roles
        } else {
          return []
        }
      },
      // availableMenuItems() {
      //   return this.handleMenuLang.filter(
      //     (item) => true
      //     // this.assignedRoles.includes(item.role)
      //   )
      // },
      availableMenuItems() {
        return this.handleMenuLang.filter((item) =>
          this.assignedRoles.includes(item.role)
        )
      },
      dialogWidth() {
        return this.$vuetify.breakpoint.smAndDown ? '95%' : '80%'
      },
    },
    methods: {
      async getCompanies() {
        this.loading = true
        this.$axios
          .get('companies?with[]=children.children.children&level_id=1&type_id=1&limit=1')
          .then((response) => {
            if (response.data.length > 0) {
              this.companiesData = response.data[0]
            }
            this.loading = false
          })
      },
      handleSelectedCompany(company) {
        this.selectedCompany = {}
        this.dialog = true
        this.loadingCardCompany = true
        this.$axios
          .get('companies/' + company.id + '?with[]=type&with[]=level')
          .then((response) => {
            this.selectedCompany = response.data
            this.loadingCardCompany = false
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .organization-chart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dialog.organization-chart {
    border: none;
  }
</style>
