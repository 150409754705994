<template>
  <v-card
    class="justify-center single-pie-chart"
    style="background-color: #f5f5f5"
    :loading="loading"
  >
    <v-card-title style="height: 35px; background-color: #e0e0e0; align-items: center">
      <h5>{{ title }}</h5>
      <v-spacer />
      <help :helpId="helpId" style="''" />
    </v-card-title>
    <v-divider />
    <pie-chart
      :chartData="chartData"
      style="background-color: #fff; padding: 20px"
      :options="chartOptions"
    />
    <v-divider />
    <v-footer class="px-1 py-0 my-auto" :style="'width: 100%; height: ' + leyend.height">
      <v-col cols="12" class="px-0 py-0">
        <v-row class="mx-0" no-gutters v-for="(item, index) in leyend.items" :key="index">
          <v-col cols="2" style="padding: 0px">
            <v-icon :style="'color:' + item.color + ';'"> mdi-brightness-1 </v-icon>
          </v-col>
          <v-col cols="10" style="padding: 0px">
            {{ item.text }}
          </v-col>
        </v-row>
      </v-col>
    </v-footer>
  </v-card>
</template>
<script>
  import PieChart from '@/components/ChartJS/PieChart'
  import Help from '@/components/App/Help'
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      chartData: {
        type: Object,
        required: true,
      },
      chartOptions: {
        type: Object,
        required: true,
      },
      leyend: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        required: true,
      },
      helpId: {
        type: Number,
        required: false,
      },
    },
    components: { PieChart, Help },
  }
</script>
<style Help lang="scss">
  .single-pie-chart {
    .v-card__subtitle,
    .v-card__text,
    .v-card__title {
      padding: 5px;
    }
    .v-card {
      height: 100%;
    }
    .v-toolbar__content .v-btn.v-btn--icon.v-size--default,
    .v-toolbar__extension .v-btn.v-btn--icon.v-size--default {
      height: 20px;
      width: 20px;
    }
    .v-toolbar__content {
      padding: 5px 10px;
      height: 100% !important;
    }
  }
</style>
